:root {
  /** Colors */
  --primary: #2c5cfa;
  --primary-50: #96aefd;
  --primary-25: #CAD6FD;
  --primary-5: #F5F7FF;
  --text-gray: #8c8ca1;
  --gray: #ececec;
  --bg-gray: #fafafa;
  --text-danger: #f16eb1;
  --text-critical: #970051;
  --text-success: #31d0aa;
  --bg-danger: #FFE3F2;
  --bg-success: #DDF7F0;
  --bg-warning: #FFF2C5;
  --bg-brand: #FB9495;
  --text-default: #4a4a68;
  --text-placeholder: #8c8ca190;
  --text-subtitle: #8C8CA1;
  --border-grey: #DCDCE2;

  /** System variables */
  --input-height: 50px;
  --input-border-radius: 4px;
  --card-border-radius: 8px;
  --light-grey: #D9D9D9;
}
