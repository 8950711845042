@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

@font-face {
  font-family: Road-Ratio;
  font-weight: 700;
  src: url('/fonts/RoadRadio.ttf') format('truetype');
}

@font-face {
  font-family: Road-Ratio;
  font-weight: 400;
  src: url('/fonts/RoadRadio-Light.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  color: var(--text-default);
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  color: inherit;
}

#__next {
  position: fixed;
  height: calc(100vh - calc(100vh - 100%));

  width: 100%;
  overflow: auto;
  overflow-x: hidden !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/** Default input styles */
.etba-input {
  padding: 15px;
  height: var(--input-height);
  border: 2px solid var(--gray);
  border-radius: var(--input-border-radius);
  outline: none;
  width: 100%;
  overflow: hidden;
}

.etba-input.is-select {
  padding-top: 0;
  padding-bottom: 0;
}

.etba-input.is-select.is-capitalized {
  text-transform: capitalize;
}

.etba-input.is-select.is-capitalized option {
  text-transform: capitalize;
}

.etba-input::placeholder {
  color: var(--text-gray);
}

.etba-input:focus {
  border: 2px solid var(--primary);
}

.etba-input:invalid,
.etba-input[data-invalid='true'] {
  border: 2px solid var(--text-danger);
}

.etba-input[type="number"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.etba-input_error {
  margin-left: 15px;
  margin-top: 5px;
}

.etba-input_error {
  margin-left: 15px;
  margin-top: 5px;
}

.etba-input_suggestion {
  position: absolute;
  width:  100%;
  right: 0;
  background: var(--bg-gray);

  padding: 10px 20px;
  border-radius: var(--card-border-radius);
  color: var(--text-gray);
  z-index: 5;
  white-space: pre-wrap;
}

.etba-input_suggestion[data-position="bottom"] {
  bottom: -10px;
  transform: translateY(100%);
}

.etba-input_suggestion[data-position="bottom"]:after {
  content: '';
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  left: 50%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--bg-gray);
  clear: both;
  transform: rotate(180deg) translateX(50%);
}

.etba-input_suggestion[data-position="top"] {
  top: -10px;
  transform: translateY(-100%);
}

.etba-input_suggestion[data-position="top"]:after {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  left: 50%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--bg-gray);
  clear: both;
  transform: translateX(-50%);
}

/** Default cards styles */
.etba-card {
  box-shadow: 0 5px 30px rgba(44, 92, 250, 0.1);
  border-radius: var(--card-border-radius);
  width: 100%;
  padding: 19px 19px 15px;
  border: 2px solid white;
}

.etba-card[data-selected='true'] {
  box-shadow: none;
  border: 2px solid var(--primary-50);
}

.etba-card-header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.etba-card-description {
  margin: 8px 0 0;
}

/** Default button styles */
.etba-button {
  text-transform: uppercase;
  padding: 15px;
  width: 100%;
  text-align: center;
  border-radius: var(--input-border-radius);
  outline: none;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
}

.etba-button[data-theme='danger'] {
  border: 1px solid var(--text-danger);
  background: #fff;
}

.etba-button[data-theme='danger'] span {
  color: var(--text-danger);
  font-weight: 400;
}

.etba-button[data-theme='primary'] {
  background: var(--primary);
}

.etba-button[data-theme='primary'] span {
  color: #fff;
  font-weight: 700;
}

.etba-button[data-theme='grey'] {
  background: var(--gray);
  font-weight: 400;
}

.etba-button[data-theme='grey'] span {
  color: var(--text-default);
}

.etba-button[data-theme='grey']:hover {
  filter: contrast(105%);
}

.etba-button[data-theme='grey']:active {
  filter: contrast(110%);
}

.etba-button[data-theme='success'] {
  background: var(--text-success);
}

.etba-button[data-theme='success'] {
  color: #fff;
}

.etba-button[data-theme='orange'] {
  background: var(--text-danger);
  color: #fff;
}

.etba-button[data-theme='success-dashed'] {
  background: var(--bg-gray) !important;
  border: 1px dashed var(--text-success);
}

.etba-button[data-theme='success-dashed'] {
  color: var(--text-gray);
}

.etba-button[data-theme='primary']:hover,
.etba-button[data-theme='success']:hover,
.etba-button[data-theme='danger']:hover {
  filter: brightness(110%);
}

.etba-button[data-theme='primary']:active,
.etba-button[data-theme='success']:active,
.etba-button[data-theme='danger']:active {
  filter: brightness(120%);
}



.etba-button[data-loading='true'] span {
  visibility: hidden;
  opacity: 0;
}

.etba-button[data-loading='true']::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.etba-button[data-theme='danger'][data-loading='true']::after {
  border-top-color: var(--primary);
}

.etba-button:disabled {
  background: var(--gray);
  cursor: not-allowed;
  filter: none !important;
}

.etba-button:disabled span {
  color: var(--text-gray);
}

.etba-button span {
  font-family: Road-Ratio, sans-serif;
  font-size: 20px;
}

.etba-button[data-glare="true"]:not([data-loading="true"])::after {
  position: absolute;
  top: -1px;
  left: -500px;
  content: "";
  width: 100%;
  height: calc(100% + 2px);
  background: rgba(255, 255, 255, 0.6);
  transform: skew(-50deg);

  /* transition: 1s; */
  animation: shine 3s ease infinite;
}

@keyframes shine {
  from {left: -500px;}
  to {left: 655px;}
}


/** Util styles */

.flex-horizontal-centered {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.flex-horizontal-centered.vertical-center {
  flex: 1 1;
  justify-content: center;
}

.flex-no-space {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.flex-space-between {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.flex-form {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  max-width: 420px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.inline-flex {
  display: inline-flex;
  width: 100%;
  gap: 12px;
}

.inline-flex-center {
  justify-content: center;
}

.form-footer {
  padding-top: 45px;
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.primary-header {
  color: var(--primary);
  font-size: 24px;
  font-weight: 700;
  padding-bottom: calc(24px - 1em);
}

.form-subheader {
  padding-bottom: calc(35px - 1em);
}

.form-subheader-row {
  padding-bottom: calc(35px - 2em);
}

.form-subheader-top-padding {
  padding-top: calc(35px - 1em);
}

.span-link {
  text-decoration: underline;
  cursor: pointer;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.text--danger {
  color: var(--text-danger);
}

.text--primary {
  color: var(--primary);
}

.text-success {
  color: var(--text-success);
}

.text--grey {
  color: var(--text-gray);
}

.text--default {
  color: var(--text-default);
}

.text-subtitle {
  color: var(--text-subtitle);
}

.text--white {
  color: #fff;
}

.text--critical {
  color: var(--text-critical);
}

.text-400 {
  font-weight: 400;
}

.text-normal {
  font-weight: normal !important;
}

.text-700 {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-400 {
  font-weight: 400;
}

.text-24px {
  font-size: 24px;
}

.text-18px {
  font-size: 18px;
}

.text-16px {
  font-size: 16px;
}

.text-14px {
  font-size: 14px;
}

.text-11px {
  font-size: 11px;
}

.text-12px {
  font-size: 12px;
}

.text-14px > * {
  font-size: 14px;
}

.text-road {
  font-family: Road-Ratio, sans-serif;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.sides-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.search-result.is-match {
  color: var(--primary);
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

/*! sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */

/* Document
 * ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: border-box; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default; /* 1 */
  font-family: system-ui, /* macOS 10.11-10.12 */ -apple-system, /* Windows 6+ */ Segoe UI, /* Android 4+ */ Roboto,
    /* Ubuntu 10.10+ */ Ubuntu, /* Gnome 3+ */ Cantarell, /* KDE Plasma 5+ */ Noto Sans, /* fallback */ sans-serif,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji', /* Windows emoji */ 'Segoe UI Symbol',
    /* Linux emoji */ 'Noto Color Emoji'; /* 2 */

  line-height: 1.15; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family:
    /* macOS 10.10+ */ Menlo, /* Windows 6+ */ Consolas, /* Android 4+ */ Roboto Mono,
    /* Ubuntu 10.10+ */ Ubuntu Monospace, /* KDE Plasma 5+ */ Noto Mono, /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono, /* fallback */ monospace; /* 1 */

  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bold;
  font-size: inherit;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family:
    /* macOS 10.10+ */ Menlo, /* Windows 6+ */ Consolas, /* Android 4+ */ Roboto Mono,
    /* Ubuntu 10.10+ */ Ubuntu Monospace, /* KDE Plasma 5+ */ Noto Mono, /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono, /* fallback */ monospace; /* 1 */

  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000; /* 1 */
  text-shadow: none;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * Collapse border spacing in all browsers (opinionated).
 */

table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */

/**
 * Inherit styling in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Remove the margin in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] { /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy='true'] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled='true'],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

:root {
  /** Colors */
  --primary: #2c5cfa;
  --primary-50: #96aefd;
  --primary-25: #CAD6FD;
  --primary-5: #F5F7FF;
  --text-gray: #8c8ca1;
  --gray: #ececec;
  --bg-gray: #fafafa;
  --text-danger: #f16eb1;
  --text-critical: #970051;
  --text-success: #31d0aa;
  --bg-danger: #FFE3F2;
  --bg-success: #DDF7F0;
  --bg-warning: #FFF2C5;
  --bg-brand: #FB9495;
  --text-default: #4a4a68;
  --text-placeholder: #8c8ca190;
  --text-subtitle: #8C8CA1;
  --border-grey: #DCDCE2;

  /** System variables */
  --input-height: 50px;
  --input-border-radius: 4px;
  --card-border-radius: 8px;
  --light-grey: #D9D9D9;
}

.smoothDropdownBehavior-enter {
  transition: all ease 0.3s;
}

.smoothDropdownBehavior-enter-active {
  transition: all ease 0.3s;
}

.smoothDropdownBehavior-exit-active {
  transition: max-height ease 0.3s;
  max-height: 0 !important;
}

.app-modal__shadow {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  overflow: auto;
  z-index: 999;
  background: rgba(255, 255, 255, 0.81);
  height: 100%;

}

.app-modal {
  width: 100vw;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  position: relative;
  flex: 1 1;
}

.app-modal.is-sm {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 200px;
  max-width: 322px;
  height: -moz-fit-content ;
  height: fit-content ;
  padding: 24px 24px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(44, 92, 250, 0.4);
  transform: translateY(-50%);
}

.app-modal__content {
  padding: 34px 20px;
}

.app-modal__option {
  padding: 20px;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.15);
}

.app-modal__option[data-selected="true"] {
  background: var(--primary);
  color: #fff;
}

.app-modal-animation.app-modal__shadow {
  animation-name: modalShadowAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.app-modal-animation > .app-modal {
  transform-origin: center;
  animation-name: appModalAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.confirm-modal {
  text-align: center;
}

.app-modal__footer {
  text-align: right;
  margin-top: 1rem;
}

.app-modal__close-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 14px;
  right: 14px;
}


@keyframes appModalAppear {
  from {
    opacity: 0.1;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalShadowAppear {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(255, 255, 255, 0.81);
  }
}

.image_wrapper__l1Lmd {
  position: relative;
}

.image_loader__O2_Mu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 50px 50px !important;
  z-index: 10;
  background-color: #fff !important;
}

.image_uploader__MK75F {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 50px 50px !important;
  z-index: 10;
  background-color: #ffffff99 !important;
}

.image_image__8pLCt {
  width: 100%;
  height: 100%;
}
.cuteLayout_bg__8G7nO {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: 1280px auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: auto;
}

.cuteLayout_wrapper__ilNqc {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 140px;
}

.cuteLayout_header__xelPd {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}

.cuteLayout_imageWrapper__lavtG {
  height: 95px;
  width: 95px;
  position: absolute;
  z-index: 0;
}

.cuteLayout_flowerImage__KaO0P {
  width: 100px;
  height: 90px;
  bottom: 90px;
  right: 90px;
}

.cuteLayout_tubeImage__Lc1FX {
  height: 80px;
  width: 60px;
  bottom: 20px;
  right: 40px;
}

.cuteLayout_circleLogoImage__IGs5U {
  bottom: 20px;
  left: 40px;
  transform: rotate(-30deg);
}

@media only screen and (hover: none) and (pointer: coarse){
  .cuteLayout_header__xelPd  {
    padding-top: 60px;
  }
}

.formLayout_wrapper__EE44H {
  width: 100%;
  padding: 20px;
  padding-top: 0;

  min-height: 100%;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.formLayout_wrapper__EE44H[data-withContentPadding="false"] {
  padding: 20px 0;
  padding-top: 0;
}

.formLayout_header__m9UA3 {
  padding-bottom: 20px;
  margin-bottom: 10px;
  margin-left: -20px;
  width: calc(100% + 40px);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.formLayout_header__m9UA3[data-withContentPadding="false"] {
  padding-left: 20px;
  padding-right: 20px;
}

.formLayout_header__m9UA3 svg {
  cursor: pointer;
}

.formLayout_userIcon__KxOVB {
  margin-right: 10px;
  color: var(--text-default);
}

.formLayout_userIcon__KxOVB[data-active='true'] > path {
  color: var(--primary);
}

.formLayout_notifications__seDFe {
  position: relative;
  margin-right: 14px;
}

.formLayout_footer__M0rlo {
  position: fixed;
  left: 50%;
  z-index: 12;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 50px;
  max-width: 420px;
  box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.formLayout_footerItem__gwY_8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  color: var(--text-default);
  cursor: pointer;
}

.formLayout_footerItem__gwY_8:hover {
  color: var(--primary);
}


.formLayout_footerItem__gwY_8[data-open="true"] {
  color: var(--primary);
}


.formLayout_footerItemTitle__XEzYU {
  font-family: Inter, 'Montserrat';
  font-size: 10px;
  color: var(--text-gray);
}


.formLayout_footerItem__gwY_8[data-open="true"] .formLayout_footerItemTitle__XEzYU {
  color: var(--primary);
}

.formLayout_surveyTile__UGEq0 {
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid var(--primary);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}

.splatNumberInput_wrapper__YpPGZ {
  display: inline-flex;
  gap: 8px;
}

.splatNumberInput_input__Azl8w {
  height: calc(var(--input-height) * 2);
  font-family: 'Saira ExtraCondensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 80px;
  width: 48px;
  outline: none;
  border: 2px solid var(--gray);
  color: var(--primary);
  caret-color: var(--text-default);
  text-align: center;
  border-radius: 6px;
}

.splatNumberInput_input__Azl8w[data-invalid='true'] {
  background: var(--gray);
}

.splatNumberInput_input__Azl8w[data-error="true"] {
  border-color: var(--text-danger);
}

.circleCheckbox_checkbox__gukxZ {
    position: relative;
    height: 28px;
    width: 28px;
    flex-shrink: 0;
}

.circleCheckbox_checkbox__gukxZ label {
    border: 1px solid var(--text-default);
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.circleCheckbox_checkbox__gukxZ label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.circleCheckbox_checkbox__gukxZ input[type="checkbox"] {
    visibility: hidden;
}

.circleCheckbox_checkbox__gukxZ input[type="checkbox"]:checked + label {
    background-color: var(--primary);
    border-color: var(--primary);
}

.circleCheckbox_checkbox__gukxZ input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
.tabs_wrapper__WWSmv {
  display: inline-flex;
  width: 100%;
}

.tabs_tab__rmhem {
  height: var(--input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  border: 1px solid transparent;
}

.tabs_tab__rmhem:hover {
  color: var(--text-gray);
}

.tabs_tab__rmhem[data-active='true'] {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: var(--input-border-radius);
}

.phoneInput_phoneInput__tuXf7 {
    padding-left: calc(15px + 1ch);
}

.phoneInput_wrapper__z0Pea {
    width: 100%;
    position: relative;
}

.phoneInput_plus__EzWes {
    position: absolute;
    left: 15px;
    top: calc(var(--input-height) / 2);
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    align-items: center;
}
.authPage_paragraph__fr5iE {
    margin: 0;
    padding-top: 35px;
}

.authPage_smilingFace__wCkza {
    transform: scale(0.5);
}

.authPage_wrapper__mX1rv {
    height: auto;
    flex-grow: 1;
    padding: 65px 10% 0;
    padding-bottom: 40px;
}

.authPage_subheader__t6GHh {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 65px;
    position: relative;
    width: 100%;
    max-width: 350px;
}

.authPage_leftStar__LjdIC {
    position: absolute;
    left: 0;
    bottom: 33px;
    width: 33px;
    height: 33px;
}

.authPage_rightStart__6o0ES {
    position: absolute;
    right: 0;
    top: -20%;
    width: 33px;
    height: 33px;
}

.circleLogoLoader_wrapper__PlT5j {
    position: relative;
    width: 60px;
    height: 60px;

    animation-name: circleLogoLoader_spin__4SiAM;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes circleLogoLoader_spin__4SiAM {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loaderPage_page__DGWz4 {
    height: 100%;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
}
